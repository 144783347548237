/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import Accordion from 'components/Accordion/_default/Accordion';
import { SidebarNavProp } from "types";

const commonChildNavClass = "peer flex items-center pr-[10px] pl-[48px] py-[10px] mt-[10px] hover:bg-theme_1_white hover:font-bold text-xs";
const activeChildNavClass = "bg-theme_1_black font-bold text-white hover:!bg-theme_1_black";


const NavGroup: React.FC<{ navGroup: SidebarNavProp, showFullWidthNavbar: boolean, onClick: Function }> = ({ navGroup: nav, showFullWidthNavbar = true, onClick }) => {
  const router = useRouter();
  const activeChildNavId = (nav.childNavs || []).find(c => router.pathname.startsWith(c.id))?.id || '';

  return (
    <>
      <div className={`${commonChildNavClass} !pl-[16px] ${activeChildNavId ? activeChildNavClass : ''} ${showFullWidthNavbar ? "hidden" : ""}`}>
        <div className='w-[24px] h-[24px] min-w-[24px] min-h-[24px]'>
          <Image width={24} height={24} src={nav.icon || "/"} alt={nav.navId} loading="eager" />
        </div>
      </div>
      <Accordion
        open={!!activeChildNavId}
        wrapperClass={`mb-[10px] peer-last-of-type:mb-0 py-[10px] !gap-0 !w-[98.6%] border-l-transparent hover:bg-theme_1_white ${activeChildNavId ? 'bg-theme_1_white' : ''} ${!showFullWidthNavbar ? "hidden" : ""}`}
        openWrapperClass='bg-theme_1_white'
        containerClass='!justify-start !items-center'
        renderHeader={(
          <div
            id={nav.navId}
            className='pl-[16px] pr-[10px] flex items-center hover:font-bold'
          >
            <div className='w-[24px] h-[24px] min-w-[24px] min-h-[24px]'>
              <Image width={24} height={24} src={nav.icon || "/"} alt={nav.navId} loading="eager" />
            </div>
            <span className='ml-[10px] whitespace-nowrap'>{nav.label}</span>
          </div>
        )}
        renderContent={(
          <React.Fragment>
            {
              (nav.childNavs || []).map((navItem, index) => (
                <Link 
                  key={navItem.label}
                  href={navItem.link || "/"}
                  passHref
                  prefetch={false}
                >
                  <a
                    id={navItem.navId}
                    className={`${commonChildNavClass} ${navItem.id === activeChildNavId ? activeChildNavClass : ""}`}
                    onClick={() => onClick({ heading: nav.label, label: navItem.label, index })}
                  >
                    { navItem.icon ? <Image src={navItem.icon || "/"} alt={navItem.navId} /> : null }
                    <span className='whitespace-nowrap'>{navItem.label}</span>
                  </a>
                </Link>
              ))
            }
          </React.Fragment>
        )}
      />
    </>
  );
};

NavGroup.defaultProps = {
  showFullWidthNavbar: true,
};

export default NavGroup;
